<template>
  <div class="subscription">
    <Header />
    <b-container class="subscription-container">
      <b-row class="justify-content-md-start back-nav">
        <div class="col-lg-12 col-md-6">
          <b-link :to="'/subscription/' + product.fund_code"><b-icon class="mx-2"
              icon="chevron-left"></b-icon><span>Kembali</span></b-link>
        </div>
      </b-row>

      <b-row class="justify-content-md-center p-4">
        <b-card class="subscription-review">
          <b-form>
            <div class="review-title">
              <b-row class="justify-content-center my-2">
                <h4>Ringkasan Transaksi Investasi Anda</h4>
              </b-row>
            </div>
            <div class="fund-name">
              <b-row>
                <div class="col-2 col-lg-1 text-left mb-2">
                  <div v-if="product.image == null">
                    <img :src="'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                      product.fund_name
                      " />
                  </div>
                  <div v-else>
                    <!-- <img
                      :src="`${imageCore}/images/products/${product.image}`"
                      alt="Fund Image"
                    /> -->
                    <img :src="product.investment_manager.logo" />
                  </div>
                </div>
                <div class="col-10 px-4">
                  <h4>
                    {{ product.fund_name }}
                  </h4>
                  <p v-text="product.investment_manager.im_name"></p>
                </div>
              </b-row>
            </div>

            <div class="purchase-review">
              <b-row class="mt-4">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Pembelian</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ product.fund_ccy }}{{ formatPrice(invest_nominal) }}
                  </p>
                </div>
              </b-row>
            </div>
            <hr />
            <div class="subscription-cost">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Biaya Pembelian</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="variant">
                    {{ product.fee_buy_text ? product.fee_buy_text : "-" }}
                  </p>
                </div>
                <!-- <div class="col-6 my-auto text-right">
                  <p v-if="user.individual.sales_id != null" class="variant">
                    {{ product.fee_buy_text ? product.fee_buy_text : "-" }}
                  </p>
                  <p v-else class="value">Gratis</p>
                </div> -->
              </b-row>
            </div>
            <hr />
            <div class="total-purchase">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Available Cash Balance</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ product.fund_ccy
                    }}{{ formatPriceCustom(balancePosition) }}
                  </p>
                </div>
              </b-row>
            </div>
            <hr />
            <div class="total-purchase">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Total</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ product.fund_ccy }}{{ formatPrice(invest_nominal) }}
                  </p>
                </div>
              </b-row>
            </div>

            <hr />
            <!-- <div class="custodian-bank">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Rekening Produk</p>
                </div>
                <div
                  class="col-6 my-auto text-right"
                  v-for="(value, index) in product.rekening"
                  :key="index"
                >
                  <p class="custodian-bank-name">
                    {{ value.bank.bank_name ? value.bank.bank_name : "-" }}
                  </p>
                  <p class="custodian-bank-name">
                    {{ value.account_number ? value.account_number : "-" }}
                  </p>
                  <p class="value mt-n3">
                    {{ value.account_name ? value.account_name : "-" }}
                  </p>
                </div>
              </b-row>
            </div> -->
            <div class="price-terms mt-4">
              <b-row>
                <div class="col-lg-12 col-md-6">
                  <p>
                    <img src="@/assets/img/icons/product/transaction-rules.svg" alt="Ketentuan Transaksi"
                      class="mx-2" />Ketentuan
                  </p>
                </div>
              </b-row>
            </div>
            <div class="transaction-time-before ml-3">
              <b-row class="mt-2">
                <!-- <p class="before">Transaksi sebelum 13:00 WIB</p> -->
              </b-row>
              <b-row>
                <p class="before-detail text-justify">
                  Batas waktu konfirmasi Pembelian Reksa Dana paling lambat
                  diterima dan tercatat oleh Aplikasi CGS iTrade Fund adalah pukul
                  13:00 WIB untuk diproses menggunakan harga NAV/Unit di hari
                  bursa yang sama.
                  Transaksi Pembelian yang diterima dan tercatat melebihi batas
                  waktu pukul 13:00 WIB akan diproses dengan menggunakan harga
                  NAV/Unit hari bursa berikutnya.
                </p>
              </b-row>
            </div>
            <hr />
            <div class="transaction-time-before ml-3 mt-3">
              <b-row>
                <p class="before-detail">
                  Biaya Transfer akan timbul jika terdapat perbedaan antara Bank RDN & Bank Penampung
                  <!-- Biaya dan Perpajakan akan timbul atas dasar ketentuan
                  peraturan perundangan-undangan yang berlaku, sesuai dengan
                  transaksi yang dimiliki. -->
                </p>
              </b-row>
            </div>
            <hr />
            <div class="agreement-checkbox">
              <b-row>
                <div class="custom-control custom-checkbox mb-4">
                  <input id="agree" type="checkbox" value="accepted" class="custom-control-input mr-2"
                    v-model="checked" />
                  <label for="agree" class="custom-control-label text-justify">
                    Saya telah membaca, memahami, dan menyetujui seluruh isi
                    <b-link :href="imageCore + '/prospectus/download/' + product.prospectus
                      " class="prospectus-link text-danger text-decoration-none" target="_blank">Prospektus</b-link>
                    dan memahami risiko investasi yang saya buat.
                  </label>
                </div>
                <b-button block :disabled="!checked || loading" @click="buy()" class="btn-tertiary py-2">
                  <div v-if="loading">
                    <div class="spinner-border spinner-border-sm"></div>
                    Loading
                  </div>
                  <div v-else>Bayar</div>
                </b-button>
              </b-row>
            </div>
          </b-form>
        </b-card>
      </b-row>
    </b-container>
    <template>
      <b-modal id="modal-failed-buy" v-model="failedBuy" size="md" class="modal-failed-buy" centered hide-footer
        hide-header no-close-on-backdrop>
        <div class="d-block text-center">
          <img src="@/assets/img/ekyc/data-not-correct.svg" alt="data-not-correct" />
          <h1 class="header-modal p-4 my-2 text-dark">
            Nominal tidak memenuhi minimal pembelian produk
          </h1>
          <b-row class="justify-content-center">
            <b-button :to="'/subscription/' + product.fund_code" block
              class="mt-3 mx-2 btn-tertiary py-2">Kembali</b-button>
          </b-row>
        </div>
      </b-modal>

      <div class="modal-success-checkout">
        <b-modal id="waitingSalesModal" v-model="waitingSalesModal" ref="waitingSalesModal" size="md"
          class="modal-failed-register" centered hide-footer hide-header no-close-on-backdrop>
          <div class="d-block text-center">
            <img src="@/assets/img/icons/transactions/checked-blue.svg" alt="" />
            <h1 class="header-modal p-4 my-2">
              Intruksi Pembelian Anda telah kami terima
            </h1>
            <p>Mohon menunggu konfirmasi. Tim kami akan segera memproses</p>
            <b-row class="justify-content-center">
              <b-button to="/orders" block class="mt-3 mx-2 btn-tertiary py-2">Aktivitas Dalam Proses</b-button>
            </b-row>
          </div>
        </b-modal>
      </div>
    </template>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import Header from "@/components/partials/header/Header.vue";
import Footer from "@/components/partials/footer/Footer.vue";

import instance from "@/utils/api";

export default {
  name: "SubscriptionReview",
  metaInfo: {
    title: "Tinjauan Pembelian | CGS iTrade Fund",
  },
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      product: {
        investment_manager: {
          im_name: "",
        },
      },
      balancePosition: [],
      fund_code: "",
      checked: false,
      invest_nominal: null,
      loading: false,
      processing: false,
      failedBuy: false,
      waitingSalesModal: false,
      clientId: localStorage.checkUserId,
      imageCore: process.env.VUE_APP_IMAGE_URL,
    };
  },
  computed: {
    ...mapGetters({
      bearer_token: "bearer_token",
      app_url: "app_url",
      user: "auth/user",
    }),
  },

  created() {
    this.fund_code = this.$route.params.fund_code;
    this.fetchFund();
    this.fetchBalancePositions();
  },

  mounted() {
    if (localStorage.invest_nominal)
      this.invest_nominal = localStorage.invest_nominal.replace(/\D/g, "");
    if (this.invest_nominal == null) {
      this.failedBuy = true;
    }
  },

  methods: {
    async fetchFund() {
      this.loading = true
      let response = await axios({
        method: "GET",
        url: `${this.app_url}product/${this.fund_code}`,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.data.data) {
        return this.$router.push("/NOT-FOUND");
      }
      this.product = response.data.data;
      this.loading = false
    },
    async fetchBalancePositions() {
      this.loading = true
      let response = await instance({
        method: "GET",
        url: `${this.app_url}balance/position?client_id=${this.clientId}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.access_token}`,
        },
      });
      this.balancePosition = response.data.data.amount;
      this.loading = false
    },

    buy() {
      if (!this.processing) {
        this.processing = true;
        var postData = {
          ifua_number:
            this.user.individual != null
              ? this.user.individual.ifua
              : this.user.institutional.ifua,
          fund_code: this.fund_code,
          product_id: this.product.id,
          amount_nominal: this.invest_nominal,
          nav_date: this.product.nav.nav_date,
          // fee: 0, Modif 06 Feb 2023
          fee: this.product.fee_subs,
          fee_type: "PERCENT", // "NOMINAL",
          individual_id:
            this.user.individual != null ? this.user.individual.id : null,
          institutional_id:
            this.user.institutional != null ? this.user.institutional.id : null,
        };
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${this.bearer_token}`,
          },
        };

        instance
          .post(`${this.app_url}subscription-post`, postData, axiosConfig)
          .then((res) => {
            console.log("AXIOS SUCCESS: ", res);
            localStorage.removeItem("invest_nominal");

            this.loading = !false;

            if ((this.user.individual || this.user.institutional).sales_id != null) {
              this.waitingSalesModal = true;
            } else {
              window.setTimeout(function () {
                window.location.href = "/orders/";
              }, 3000);
            }
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            this.processing = false;
          });
      }
    },
    formatPriceCustom(value) {
      let val = Math.floor(value).toFixed(0).replace(".", ",");
      // let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription {
  background: #f3f4f6;
}

.subscription-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

.subscription-review {
  padding: 20px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #4b5563;
}

.back-nav {
  padding: 0px 260px 0px 248px;

  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #6b7280;
    text-decoration: none;
  }
}

.subscription-review {
  width: 600px;
}

.review-title {
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
    margin-bottom: 0px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    color: #6b7280;
  }
}

hr {
  margin: 0 !important;
}

.purchase-review {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.subscription-cost {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #38a169;
  }
}

.total-purchase {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.custodian-bank {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }

  .custodian-bank-name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #111827;
  }

  .value {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #111827;
  }
}

.price-terms p {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #111827;
}

.transaction-time-before {
  .before {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }

  .before-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #4b5563;
  }
}

.transaction-time-after {
  .after {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }

  .after-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #4b5563;
  }
}

.agreement-checkbox {
  margin-top: 40px;
  padding-left: 18px;
  padding-right: 18px;

  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: $secondaryColor;
}

.custom-checkbox:active {
  box-shadow: none !important;
}

.custom-checkbox:focus {
  box-shadow: none !important;
}

.header-modal {
  font-size: 32px;
}

@media only screen and (max-width: 768px) {
  .back-nav {
    padding: 0px;
  }

  h4 {
    font-size: 16px !important;
  }

  p {
    font-size: 14px !important;
  }

  label {
    font-size: 14px !important;
  }

  .header-modal {
    font-size: 16px !important;
  }
}
</style>
