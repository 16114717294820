import axios from "axios";

const instance = axios.create({
  withCredentials: true,
  // timeout: 20000
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.dispatchEvent(new Event("session-expired"));
    }
    return Promise.reject(error);
  }
);

export default instance;
